<template>
  <el-container>

    <el-dialog
      v-model="showTermsAcceptanceDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="50%">

      <el-row>
        <el-col style="word-break: break-word;">
          <h3>Haftungsausschluss</h3>
          Die Software Dacy dient der Dokumentation von Tumorbehandlungen. Die in der Software angebotenen wissenschaftlichen, therapeutischen und pharmakologischen Informationen (Knowledebase) dienen der Vereinfachung der Dokumentation. Es kann keine Gewähr für die Vollständigkeit und Richtigkeit dieser Informationen gegeben werden. Die Benutzer der Software sind aufgefordert fehlende oder falsche Informationen der Redaktion über die Benachrichtigungsfunktion der Software mitzuteilen. Die behandelnden Ärzte sind verpflichtet die von ihnen durchführten Behandlungen entsprechend der Leitlinien der Fachgesellschaften, der Fachinformationen und auf Grundlage des aktuellen Standes der Wisssenschaft durchzuführen. Abweichende oder fehlende Informationen in der Software Dacy dürfen nicht als Grundlage für die Behandlung von Patienten herangezogen werden.
          <p class="mt-4">
            <el-checkbox v-model="disclaimerAccepted" label="Ich habe den Haftungsausschluss gelesen und akzeptiert." size="large" border  />
          </p>
        </el-col>
      </el-row>

      <div class="text-center">
        <hr/>
        <el-button :disabled="!disclaimerAccepted" type="warning" @click="onSaveAcceptance">Speichern und weiter zu DACY</el-button>
      </div>

    </el-dialog>

    <el-header style="background-color:#FFF;">
      <el-row class="pt-2 pb-2">
        <el-col :xs="11" :sm="9" :span="7" :xl="4" :lg="6">
          <h3 class="mt-2">Knowledgebase</h3>
        </el-col>
        <el-col :xs="9" :sm="11" :span="5" :xl="16" :lg="14">

        </el-col>
        <el-col :span="2" style="line-height:50px; text-align:right">
        </el-col>
        <el-col :sm="2" :span="2" class="text-right align-content-end">
          <main-menu></main-menu>
        </el-col>
      </el-row>
    </el-header>
    <div class="w-100" style="background-color:#f39200; height:8px;"></div>
    <el-main>

      <el-card class="box-card">

        <el-row>
          <el-col :span="7">
            <h6 class="mb-3">Diagnose</h6>
            <h5 v-if="currentDiagnosis">{{currentDiagnosis.title}}</h5>
            <span v-else><i class="el-icon el-icon-loading" style="font-size:1.5em;"></i></span>
          </el-col>
          <el-col :offset="1" :span="7">
            <h6>Behandlungssituation / Therapiemodalität</h6>
            <el-select size="medium" class="w-100" v-model="form.treatmentsituation" filterable :disabled="filterIsDisabled">
              <el-option :value="null" label="-- Keine Auswahl --"></el-option>
              <el-option
                v-for="item in allTreatmentSituations"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :offset="1" :span="7">
            <h6>Biomarkermuster</h6>
            <el-select size="medium" class="w-100" v-model="form.biomarker" filterable :disabled="filterIsDisabled">
              <el-option :value="null" label="-- Keine Auswahl --"></el-option>
              <el-option
                v-for="item in allBiomarker"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>


        <hr/>

        <h6>Therapieprinzip auswählen</h6>

        <el-row>
          <el-col :span="6" class="round">

            <i v-if="!currentDiagnosis" class="el-icon el-icon-loading" style="font-size:1.5em;"></i>
            <el-table
              v-else
              :data="therapies"
              style="width: 100%"
              :show-header="false"
              highlight-current-row
              @row-click="onTherapyRowClick"
              height="60vh"
              stripe>
              <el-table-column>
                <template #default="scope">
                  {{ scope.row.code }} <el-tag v-if="therapyInInlabel(scope.row, 1)" type="success" size="mini">In</el-tag>
                  <el-tag v-if="therapyInInlabel(scope.row, 0)" type="danger" size="mini">off</el-tag>
                  <el-tag v-if="therapyInInlabel(scope.row, 2)" type="warning" size="mini">ex</el-tag>
                  <el-tag v-if="therapyInInlabel(scope.row, 3)" type="danger"  size="mini" style="text-decoration:line-through;" >in</el-tag>
                  <el-tag v-if="therapyInInlabel(scope.row, 4)" type="success" size="mini">off</el-tag>
                  <i v-if="therapyInInlabel(scope.row, 5)" class="ml-1 text-danger el-icon el-icon-circle-close"></i>

                  <el-tooltip v-if="therapyHasSurveys(scope.row)" placement="top">
                    <template #content>
                      <div :key="survey.id" v-for="survey of surveysOfTherapy(scope.row)">{{survey.title_short}}</div>
                    </template>
                    <el-tag size="mini">S</el-tag>
                  </el-tooltip>

                </template>
              </el-table-column>
            </el-table>

          </el-col>
          <el-col :span="18" v-if="!currentTherapy" v-loading="currentTherapyLoading">
          </el-col>
          <el-col :span="18" v-if="currentTherapy" >

            <div class="ml-5">

              <h5 class="text-dacy">{{currentTherapy.code}}</h5>
              <div class="mb-3">{{currentTherapy.title}}</div>
              <p class="text-danger" v-if="currentTherapy.special"><i class="el-icon-warning"></i> {{currentTherapy.special}}</p>

              <div v-if="currentTherapy.therapyProtocols && currentTherapy.therapyProtocols.length > 0">

                <h5 class="mt-4">Protokolle / Therapien</h5>
                <el-collapse class="border-top-0">
                  <el-collapse-item :name="index" :key="therapyProtocol.protocol.id" v-for="(therapyProtocol, index) in currentTherapy.therapyProtocols">
                    <template #title>
                      {{(therapyProtocol.meta && therapyProtocol.meta.protocolown ? therapyProtocol.meta.protocolown.title : therapyProtocol.protocol.title)}}
                      <el-tag v-if="therapyProtocol.inlabel == 0" class="ml-1" size="mini" type="danger">off</el-tag>
                      <el-tag v-if="therapyProtocol.inlabel == 1" class="ml-1" size="mini" type="success">in</el-tag>
                      <el-tag v-if="therapyProtocol.inlabel == 2" class="ml-1" size="mini" type="warning">ex</el-tag>
                      <el-tag v-if="therapyProtocol.inlabel == 3" class="ml-1" style="text-decoration:line-through;" size="mini" type="danger">in</el-tag>
                      <el-tag v-if="therapyProtocol.inlabel == 4" class="ml-1" size="mini" type="success">off</el-tag>
                      <i v-if="therapyProtocol.inlabel == 5" class="ml-1 text-danger el-icon el-icon-circle-close"></i>
                      <el-tag v-if="therapyNotInGER(therapyProtocol)" class="ml-1" size="mini" type="danger">BRD</el-tag>

                      <el-tooltip v-if="therapyProtocol.meta && therapyProtocol.meta.surveys && therapyProtocol.meta.surveys.length > 0" placement="right">
                        <template #content>
                          <div :key="survey.id" v-for="survey of surveysOfTherapyProtocol(therapyProtocol)">{{survey.title_short}}</div>
                        </template>
                        <el-tag class="ml-1" size="mini">S</el-tag>
                      </el-tooltip>

                    </template>

                    <div v-if="therapyProtocol.meta.protocolown"><h6>Protokollname DACY</h6>{{therapyProtocol.protocol.title}}</div>
                    <div v-else-if="therapyProtocol.protocol.kz_title"><h6>Protokollname lokal</h6>{{therapyProtocol.protocol.kz_title}}</div>

                    <div v-if="therapyProtocol.inlabel == 1">inlabel</div>
                    <div v-if="therapyProtocol.inlabel == 0">offlabel</div>
                    <div class="mt-3" v-if="therapyProtocol.specifics">
                      <h6>Besonderheiten</h6>
                      {{therapyProtocol.specifics}}
                    </div>
                    <div class="mt-3" v-if="therapyProtocol.duration">
                      <h6>Dauer</h6>
                      {{therapyProtocol.duration}}
                    </div>
                    <div v-if="therapyProtocol.protocol.drugs" class="mb-3 mt-3">
                      <h6>Fachinformationen</h6>
                      <div v-for="drug in therapyProtocol.protocol.drugs" :key="drug.id">
                        <span v-if="drug.pdf_link"><a href="javascript:void(0)" @click="openBrowser(drug.pdf_link)">{{drug.title}}</a></span>
                        <span v-else>{{drug.title}}</span>
                        <el-tag v-if="!drug.available" class="ml-1" size="mini" type="danger">BRD</el-tag>
                      </div>
                    </div>
                    <div v-if="therapyProtocol.protocol.description"><h6>Beschreibung</h6>{{therapyProtocol.protocol.description}}</div>
                    <div v-if="therapyProtocol.protocol.special"><h6>Besonderheiten</h6>{{therapyProtocol.protocol.special}}</div>
                    <div v-if="therapyProtocol.protocol.oral"><h6>Oral</h6>{{therapyProtocol.protocol.oral}}</div>

                    <div v-if="showTherapyProtocolStudyResults && therapyProtocol.studyresults && therapyProtocol.studyresults.length > 0">
                      <h6 class="mt-2">Studienergebnisse</h6>
                      <el-collapse :model-value="0" accordion class="border-top-0 border-bottom-0">
                        <el-collapse-item :name="studyindex" :title="studyResult.study.title" :key="studyResult.id" v-for="(studyResult, studyindex) in therapyProtocol.studyresults">
                          <h6 v-if="studyResult.pro_con == 1">Endpunkte</h6>
                          <h6 v-if="studyResult.pro_con == 2">Einschränkungen</h6>
                          <study-result-description :description="studyResult.description" :literature="studyResult.study.literature"></study-result-description>
                          <h6 v-if="studyResult.study.literature.length" class="mt-2">Literatur</h6>
                          <p :key="literature.id" v-for="literature in studyResult.study.literature">
                            <span v-if="literature.link_pubmed"><a href="javascript:void(0)" @click="openBrowser(literature.link_pubmed)">{{literature.signature}}</a></span>
                            <span v-else>{{literature.signature}}</span>
                          </p>
                          <h6 class="mt-4" v-if="studyResult.study.ECOG.length">ECOG</h6>
                          <span class="mr-3" :key="ECOG.id" v-for="ECOG in studyResult.study.ECOG">
                        <el-tooltip placement="bottom">
                          <template #content><span v-if="ECOG.ecog_percent">{{ECOG.ecog_percent}}<br/></span>{{ ECOG.meta.description }}</template>
                          <el-tag>{{ECOG.meta.ecog}}</el-tag>
                        </el-tooltip>

                      </span>
                        </el-collapse-item>
                      </el-collapse>
                    </div>

                  </el-collapse-item>
                </el-collapse>

              </div>

            </div>
          </el-col>
        </el-row>

      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import MainMenu from "@/components/parts/MainMenu";
import {mapActions, mapGetters, mapState} from "vuex";
import _ from "lodash";
import axios from "axios";
import StudyResultDescription from "./components/parts/StudyResultDescription.vue";

export default {
  name: "PublicKnowledgebase",
  props: [],
  components: {
    StudyResultDescription,
    MainMenu,
  },
  data () {
    return {
      currentTherapy: null,
      currentTherapyLoading: false,
      currentTherapyProtocol: null,
      currentReferer: null,
      currentDiagnosis: null,
      allTreatmentSituations: [],
      allBiomarker: [],
      filterTherapyProtocolIds: null,
      showTermsAcceptanceDialog: false,
      disclaimerAccepted: false,
      showTherapyProtocolStudyResults: true,
      form: {
        therapies: [],
        diagnosis: null,
        treatmentsituation: null,
        biomarker: 18,
        only_protocol_own: false,
        public: true
      },
    }
  },
  mounted: async function () {

    if(!localStorage.getItem("dacy-dislaimer-accepted")) {
      this.showTermsAcceptanceDialog = true
    }
    else {
      await this.init()
    }

  },
  computed: {
    ...mapGetters('diagnosis', ['publicDiagnosis']),
    ...mapState('therapies', ['therapies']),
    ...mapState('diagnosis', ['publicDiagnosis']),
    ...mapState('biomarker', ['publicBiomarker']),
    ...mapState('treatmentsituations', ['publicTreatmentsituations']),
    filteredDiagnosis() {

      if(this.diagnosisSearch !== '') {
        const search = this.diagnosisSearch.toLowerCase()
        let filtered = []
        for(const diag of this.publicDiagnosis) {
          if(diag.code.toLowerCase().indexOf(search) !== -1 || diag.title.toLowerCase().indexOf(search) !== -1) {
            filtered.push(diag)
          }
        }
        return filtered
      }

      return this.publicDiagnosis
    }
  },
  watch: {
    'form.diagnosis': async function () {
      await this.getTherapies(this.form)
      this.filterTreatmentSituations()
      this.filterBiomarker()
    },
    'form.treatmentsituation': async function () {
        this.form.biomarker = null
        await this.getTherapies(this.form)
        this.filterBiomarker()
    },
    'form.biomarker':async function () {
        await this.getTherapies(this.form)
    }
  },
  methods: {
    ...mapActions('diagnosis', ['getPublicDiagnosis']),
    ...mapActions('biomarker', ['getPublicBiomarker']),
    ...mapActions('treatmentsituations', ['getPublicTreatmentsituations']),
    ...mapActions('therapies', ['getTherapies']),
    async init() {

      await this.getPublicDiagnosis()
      await this.getPublicBiomarker()
      await this.getPublicTreatmentsituations()

      if(this.$route.query && this.$route.query.d) {
        console.log("query", this.publicDiagnosis)
        const exist = _.find(this.publicDiagnosis, (d) => d.id == this.$route.query.d)
        if(exist) {
          console.log(exist)
          this.currentDiagnosis = exist
          this.form.diagnosis = this.$route.query.d
        }
      }

      await this.getTherapies(this.form)

      this.allTreatmentSituations = {...this.publicTreatmentsituations}
      this.allBiomarker = {...this.publicBiomarker}

    },
    filterTreatmentSituations() {
      let filteredTreatmentSituations = []
      let addedTreatmentSituations = []
      let allTherapies = JSON.parse(JSON.stringify(this.therapies))

      for(const therapy of allTherapies) {

        if(therapy.treatmentSituation && addedTreatmentSituations.indexOf(therapy.treatmentSituation.id) === -1) {
          addedTreatmentSituations.push(therapy.treatmentSituation.id)
          filteredTreatmentSituations.push(therapy.treatmentSituation)
        }

      }
      filteredTreatmentSituations = _.sortBy(filteredTreatmentSituations, [function(o) { return o.title.toLowerCase(); }])
      this.allTreatmentSituations = filteredTreatmentSituations

    },
    filterBiomarker() {
      let filteredBiomarker = []
      let addedBiomarker = []
      let allTherapies = JSON.parse(JSON.stringify(this.therapies))

      for(const therapy of allTherapies) {

        if(therapy.biomarker && therapy.biomarker.length) {
          for(const biomarker of therapy.biomarker) {
            if(addedBiomarker.indexOf(biomarker.id) === -1) {
              addedBiomarker.push(biomarker.id)
              filteredBiomarker.push(biomarker)
            }
          }
        }

      }
      filteredBiomarker = _.sortBy(filteredBiomarker, [function(o) { return o.title.toLowerCase(); }])
      this.allBiomarker = filteredBiomarker

    },
    async onTherapyRowClick(row) {
      this.currentTherapy = null
      this.currentTherapyLoading = true
      const result = await axios.get('/public/therapies/' + row.id)
      if(result.status === 404) {
        this.$notify.error({
          message: "Therapie wurde nicht gefunden",
          position: 'bottom-left'
        });
      }
      else {
        this.currentTherapy = result.data
        if(this.filterTherapyProtocolIds && this.filterTherapyProtocolIds.length && this.filterTherapyProtocolIds.length > 0) {
          let newTherapyProtocols = []
          for(const therapyProtocol of this.currentTherapy.therapyProtocols) {
            if(this.filterTherapyProtocolIds.indexOf(therapyProtocol.id) !== -1) {
              newTherapyProtocols.push(therapyProtocol)
            }
          }
          console.log(newTherapyProtocols)
          this.currentTherapy.therapyProtocols = newTherapyProtocols
        }
      }
      this.currentTherapy.therapyProtocols = _.sortBy(this.currentTherapy.therapyProtocols, [function(o) { return (o.meta && o.meta.protocolown ? o.meta.protocolown.title.toLowerCase() : o.protocol.title.toLowerCase()) }])
      this.currentTherapyLoading = false
    },
    openBrowser(url) {
      window.open(url, "_blank");
    },
    therapyInInlabel(therapy, needle) {
      const groups = _.groupBy(therapy.therapyProtocols, 'inlabel')
      return !!groups[needle]
    },
    therapyNotInGER(therapyProtocol) {
      if(!therapyProtocol.protocol) return false
      return _.find(therapyProtocol.protocol.drugs, (p) => p.available === false)
    },
    therapyHasSurveys(therapy) {
      if(!therapy.therapyProtocols) return false
      return _.find(therapy.therapyProtocols, (p) => (p.meta && p.meta.surveys && p.meta.surveys.length > 0))
    },
    surveysOfTherapyProtocol(therapyProtocol) {
      let therapySurveys = []
      if(therapyProtocol.meta && therapyProtocol.meta.surveys && therapyProtocol.meta.surveys.length > 0) {
        for(const tp of therapyProtocol.meta.surveys) {
          therapySurveys.push(tp.survey)
        }
      }
      return therapySurveys
    },
    surveysOfTherapy(therapy) {
      if(!therapy.therapyProtocols) return []
      let therapySurveys = []
      for(const p of therapy.therapyProtocols) {
        if(p.meta && p.meta.surveys && p.meta.surveys.length > 0) {
          for(const tp of p.meta.surveys) {
            therapySurveys.push(tp.survey)
          }
        }
      }
      return therapySurveys
    },
    async onSaveAcceptance() {
      localStorage.setItem("dacy-dislaimer-accepted", true)
      this.showTermsAcceptanceDialog = false
      await this.init()
    }
  }
}
</script>
<style>
</style>
